<template>
    <div><LeaveCredit :is-admin="true" /></div>
</template>

<script setup>
import LeaveCredit from '@/components/Requests/Dev/Leave/LeaveCredit/index.vue';
export default {
    components: {
        LeaveCredit,
    },
};
</script>

<style scoped lang="scss"></style>
